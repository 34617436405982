import FormNavigation from "../../components/FormNavigation";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToast } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
	ContractFormSchema,
	ContractFormType,
	DiscountFormType,
} from "./ContractValidationSchemas";
import { contractFormSteps } from "./ContractFormConfig";
import { setStep } from "./contractFormSlice";
import {
	useCreateContractMutation,
	useGetContractByIdQuery,
	useUpdateContractMutation,
} from "../../api/contractApi";
import { getErrorMessage } from "../../utils/helpers";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useEffect } from "react";
import Loading from "../../components/Loading";
import ContractStep0 from "./ContractStep0";
import ContractStep1 from "./ContractStep1";
import ContractStep2 from "./ContractStep2";
import { useAppSelector } from "../../app/hooks";
import { formatDateToMonthInput } from "../../utils/dateUtils";

const ContractForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();
	const toast = useToast();

	const { step } = useAppSelector((state) => state.contractForm);

	const [createContract] = useCreateContractMutation();
	const [updateContract] = useUpdateContractMutation();

	const {
		data: contractData,
		isFetching: contractIsFetching,
		isLoading: contractIsLoading,
		isError: contractIsError,
	} = useGetContractByIdQuery(
		id
			? {
					id: Number(id),
					includeClients: true,
					includeMachinesContractDetails: true,
					includeDiscounts: true,
			  }
			: skipToken
	);

	const methods = useForm<ContractFormType>({
		defaultValues: {
			contract: {
				id: contractData?.id || 0,
				startDate: contractData?.startDate
					? new Date(contractData.startDate)
							.toISOString()
							.slice(0, 10)
					: undefined,
				endDate: contractData?.endDate
					? new Date(contractData.endDate).toISOString().slice(0, 10)
					: undefined,
				clientId: contractData?.client?.id.toString() || "",
				billingType: contractData?.billingType,
			},
			machinesContractDetails:
				contractData?.machinesContractDetails?.map((detail) => ({
					...detail,
					machineId: detail.machineId.toString(),
				})) ?? [],
			discounts:
				contractData?.machinesContractDetails?.flatMap(
					(detail) =>
						detail.discounts?.map((discount) => ({
							...discount,
							startDate: new Date(discount.startDate)
								.toISOString()
								.slice(0, 10),
							endDate: new Date(discount.endDate)
								.toISOString()
								.slice(0, 10),
						})) ?? []
				) ?? [],
		},
		mode: "onBlur",
		resolver: yupResolver(ContractFormSchema),
	});

	const { machinesContractDetails, discounts } = methods.getValues();

	const handleStepSubmit = async () => {
		const fields = contractFormSteps[step].fields;

		let dynamicFields;
		switch (step) {
			case 0:
				dynamicFields = fields;
				break;
			case 1:
				dynamicFields =
					machinesContractDetails?.flatMap((_, index) =>
						fields.map((field) =>
							field.replace("*", index.toString())
						)
					) ?? [];
				break;
			case 2:
				dynamicFields =
					discounts?.flatMap((_, index) =>
						fields.map((field) =>
							field.replace("*", index.toString())
						)
					) ?? [];
				break;
		}

		const output = await methods.trigger(
			dynamicFields as Array<keyof ContractFormType>,
			{
				shouldFocus: true,
			}
		);

		if (!output) {
			return;
		}

		if (step < contractFormSteps.length - 1) {
			dispatch(setStep(step + 1));
		}
	};

	const onSubmit = async () => {
		let promise;
		try {
			const contractPayload = {
				...methods.getValues(),
				id: Number(id) || 0,
				discounts: discounts?.map((discount) => ({
					...discount,
					type: discount.discountType,
				})),
			};

			if (id) promise = updateContract(contractPayload).unwrap();
			else promise = createContract(contractPayload).unwrap();

			toast.promise(promise, {
				loading: {
					title: "A submeter o formulário",
					description: "Aguarde enquanto processamos o seu pedido.",
				},
				success: {
					title: id
						? "Contrato atualizado com sucesso"
						: "Contrato criado com sucesso",
				},
				error: (err: Error) => {
					const errorMessage = getErrorMessage(
						err as FetchBaseQueryError | SerializedError
					);
					return {
						title: id
							? "Erro ao atualizar o Contrato"
							: "Erro na criação do Contrato",
						description: errorMessage,
					};
				},
			});

			promise
				.then((result) => {
					if (result) navigate("/admin/contracts");
				})
				.catch(() => {
					return;
				});
		} catch (error) {
			console.debug("Submission error");
		}
	};

	useEffect(() => {
		if (contractData) {
			methods.reset({
				contract: {
					id: contractData.id,
					startDate: contractData.startDate
						? formatDateToMonthInput(
								new Date(contractData.startDate)
						  )
						: undefined,
					endDate: contractData.endDate
						? formatDateToMonthInput(
								new Date(contractData.endDate)
						  )
						: undefined,
					clientId: contractData.client?.id.toString() || "",
					billingType: contractData.billingType,
				},
				machinesContractDetails:
					contractData.machinesContractDetails?.map((detail) => ({
						...detail,
						machineId: detail.machineId.toString(),
					})) || [],
				discounts:
					contractData.machinesContractDetails
						?.flatMap((detail) =>
							(detail.discounts || []).map((discount) => ({
								...discount,
								machineIds: [detail.machineId.toString()],
							}))
						)
						.reduce<DiscountFormType[]>((acc, current) => {
							const existingDiscount = acc.find(
								(discount) => discount.id === current.id
							);

							if (existingDiscount) {
								// If the discount already exists, merge the machineIds
								existingDiscount.machineIds = [
									...(existingDiscount.machineIds || []),
									...current.machineIds,
								];
								existingDiscount.startDate = "";
								existingDiscount.endDate = "";
							} else {
								// If it's a new discount, add it to the accumulator
								acc.push({
									...current,
									startDate: new Date(current.startDate)
										.toISOString()
										.slice(0, 10),
									endDate: new Date(current.endDate)
										.toISOString()
										.slice(0, 10),
									discountType: current.type,
								});
							}

							return acc;
						}, []) || [],
			});
		}
	}, [contractData]);

	if (contractIsLoading || contractIsFetching) {
		return <Loading fillViewPort />;
	}

	if (contractIsError) return <p>Erro</p>;

	return (
		<FormProvider {...methods}>
			<form>
				{step === 0 && <ContractStep0 />}
				{step === 1 && (
					<ContractStep1
						clientId={Number(
							methods.getValues("contract.clientId") || 0
						)}
					/>
				)}
				{step === 2 && (
					<ContractStep2
						clientId={Number(
							methods.getValues("contract.clientId") || 0
						)}
					/>
				)}
				{/* {step === 3 && <ContractStep3 />} */}
				<FormNavigation
					maxSteps={contractFormSteps.length}
					currentIndex={step}
					onClickNext={handleStepSubmit}
					onClickBack={() => dispatch(setStep(step - 1))}
					onSubmit={methods.handleSubmit(onSubmit)}
				/>
			</form>
		</FormProvider>
	);
};

export default ContractForm;
