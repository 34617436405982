// // Generate month names in Portuguese
// export const monthNames = Array.from({ length: 12 }, (_, i) =>
// 	format(new Date(currentDate.getFullYear(), i, 1), "MMMM", {
// 		locale: pt,
// 	})
// );

export const monthNames = [
	"Janeiro",
	"Fevereiro",
	"Março",
	"Abril",
	"Maio",
	"Junho",
	"Julho",
	"Agosto",
	"Setembro",
	"Outubro",
	"Novembro",
	"Dezembro",
];

export const formatDateDayAndMonth = (date: string) => {
	const formattedDate = new Date(date);
	const month = monthNames[formattedDate.getUTCMonth()];
	const day = formattedDate.getUTCDate();

	return `${day} ${month}`;
};

export const formatDateMonth = (date: Date) => {
	const month = monthNames[date.getMonth()];
	return month;
};

export const formatDateMonthAndYear = (date: Date) => {
	const month = monthNames[date.getMonth()];
	const year = date.getFullYear();
	return `${month} - ${year}`;
};

export const getEarlierDate = (date1: Date, date2: Date): Date => {
	return date1 < date2 ? date1 : date2;
};

export const getMonthsInRange = (startDate: Date, endDate: Date): string[] => {
	// Ensure startDate is before endDate
	if (startDate > endDate) {
		return [];
	}

	if (
		startDate.getMonth() === endDate.getMonth() &&
		startDate.getFullYear() === endDate.getFullYear()
	)
		return [];

	// Normalize startDate and endDate to the first day of their respective months
	const start = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
	const end = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0); // Last day of the end month

	// Initialize an empty array to hold the result
	const months: string[] = [];

	const current = new Date(start);

	// Iterate through the months
	while (current <= end) {
		months.push(formatDateMonth(current));
		// Move to the next month
		current.setMonth(current.getMonth() + 1);
	}

	return months;
};

export const getLastDayOfMonth = (date: Date): Date => {
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
	lastDay.setHours(23, 59, 59, 999);
	return lastDay;
};

export const getFirstDayOfMonth = (date: Date): Date => {
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	firstDay.setHours(0, 0, 0, 0); // Set hour to 00:00:00
	return firstDay;
};

export const previousMonthDate = (date: Date): Date => {
	const newDate = new Date(date);
	newDate.setMonth(newDate.getMonth() - 1);
	return newDate;
};

export const isSameMonthAndYear = (date1: Date | null, date2: Date) => {
	if (date1 == null) return false;
	return (
		date1.getMonth() === date2.getMonth() &&
		date1.getFullYear() === date2.getFullYear()
	);
};

export const isInsideRange = (currentDate: Date, date1: Date, date2: Date) => {
	return (
		new Date(currentDate).getTime() >= new Date(date1).getTime() &&
		new Date(currentDate).getTime() <= new Date(date2).getTime()
	);
};

export const formatPtDateToInput = (date: string) => {
	if (date) {
		try {
			const formattedDate = new Date(date);

			// Return the date in ISO format YYYY-MM-DD
			return formattedDate.toISOString().split("T")[0];
		} catch (error) {
			console.error("Error parsing date:", error);
			return ""; // Return an empty string on error
		}
	}
	return "";
};

export const formatDate = (date: Date) => {
	return new Date(date).toLocaleDateString("pt-PT", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
	});
};

// Helper function to format Date → "YYYY-MM"
export function formatDateToMonthInput(date: Date): string {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	return `${year}-${month}`;
}
